import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
//import { ToastrModule } from 'ngx-toastr';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import {MatCardModule} from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule} from '@angular/material/table';
import { NotificationService } from '../../services/notificationService';
import { NgxPaginationModule } from 'ngx-pagination';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AddComponent } from './add/add.component';
import { ProductComponent } from './product.component';
import { ProductRoutingModule } from './product.routing';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EditComponent } from './edit/edit.component';


@NgModule({
    declarations: [
     ProductComponent,
     AddComponent,
     EditComponent
    ],
    providers: [NotificationService],
    imports: [

       
        CommonModule,
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        CollapseModule.forRoot(),
        FormsModule,
        NgxPaginationModule,
        ReactiveFormsModule,
       // ToastrModule.forRoot(),
        MatListModule,
        MatIconModule,
        MatTableModule,
        MatCardModule,
       // MatPaginator,
        MatPaginatorModule,
        //MatTableDataSource,
        MatProgressBarModule,
        MatGridListModule,
        ProductRoutingModule,
        AutocompleteLibModule,
        MatButtonToggleModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatFormFieldModule, MatSelectModule
       
        
        
       
        
    ]
})
export class ProductModule{ }
