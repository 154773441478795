import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { DataService } from '../dataservice';
import { NotificationService } from '../../../services/notificationService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrl: './add.component.scss'
})
export class AddComponent {
   keyword1 = 'productName'
   category:any[]=[];
   alertsEnabled:any;
   supplier:string[]=[];
   product:any[]=[];
   unitName:any[]=[];
   productName:any[]=[];
   unit:any[]=[];
   maintainStock: string[] = ['Yes', 'No'];
  productForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  isChecked:boolean = true;
  isEnabled = false;
  isDisabled = false;

  constructor(private data: DataService,
    private toastr:NotificationService,
    private formBuilder: FormBuilder,
    private router:Router
  ) {}

  ngOnInit()  {
    this.productForm= this.formBuilder.group({
      productName: ["",Validators.required],
      shortName: ["", Validators.required],
      unitName: ["", Validators.required],
      maintainStock: ["",],

    });
    this.data.getUnit().subscribe((val:any)=>{
      this.unit = Object.keys(val).map(key => ({ id: key, ...val[key] }));
      this.unit.forEach((val:any)=>{
       this.unitName.push(val.unitName)
      })
    })
   
  }

  selectEvent1(item:any) {
    // do something with selected item
    console.log(item)
   
    this.productForm.patchValue({
      productName:item.productName,
    })
  }
 
  onChangeSearch1(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getCategory().subscribe((val:any)=>{
      this.productName = Object.keys(val).map(key => ({ id: key, ...val[key] }));
      console.log(this.productName)
      return this.productName.filter(val => val.productName.toLowerCase().includes(search))
    })
  }

  onFocused1(e:any) {
    console.log(e)
    // do something
  }

  goBack() {
    this.router.navigate(['/dashboard/product']);
  }
  get f() {
    return this.productForm.controls;
  }
  enabled(){
    console.log(this.isEnabled)
    if(this.isEnabled === true){
    this.productForm.patchValue({
      sstatus:true
    })
  } else{
    this.productForm.patchValue({
      sstatus:false
    })
  }
  }
  onSubmit(data: any) {
    if (this.productForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.postOn = moment().format();
    data.isActive = true
    
    this.data.create(data).subscribe((res:any)=>{
     console.log(res)
      this.toastr.showSuccess("Successfully!!","Product Added")
      this.router.navigate(['/dashboard/product'])
    })
  }
  selectEvent(item:any) {
    // do something with selected item
    console.log(item)
   
    // this.productForm.patchValue({
    //   categoryName:item.category,
    //   shortName:item.shortName,
    //   supplierName:item.supplierName
    // })
  }
 
  onChangeSearch(search: string) {
    // fetch remote data from here
    console.log(search)
    this.data.getSupplier().subscribe((res:any)=>{
      this.supplier = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      console.log(this.supplier)
      this.category = [];
       this.supplier.forEach((val:any)=>{
        if (val.category && val.category.toLowerCase().trim() === search.toLowerCase().trim()) {
          this.category.push(val);
          console.log(this.category);
      }
      })

    })
  }

  onFocused(e:any) {
    console.log(e)
    // do something
  }
  onCancel(){
    this.productForm.reset();
  }
}
