<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>New Product</h2>
    <form [formGroup]="productForm">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
          <label for="productName">Product Name<span class="requried-field">*</span></label>
          <ng-autocomplete
            [data]="productName"
            [searchKeyword]="keyword1"
            placeholder="Enter the productName"
            (selected)='selectEvent1($event)'
            (inputChanged)='onChangeSearch1($event)'
            (inputFocused)='onFocused1($event)'
            historyIdentifier="productName"
            [itemTemplate]="itemTemplate1"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
        
          <ng-template #itemTemplate1 let-item>
            <a [innerHTML]="item.productName"></a>
          </ng-template>
        
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
          <div *ngIf="submitted && f['productName'].errors" class="invalid-feedback">
            <div *ngIf="f['productName'].errors['required']">Name is required</div>
          </div>
        </div>
        
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label for="shortName">Short Name<span class="requried-field">*</span></label>
            <input
              type="text"
             
              id="shortName"
              class="form-control"
              formControlName="shortName"
              [ngClass]="{ 'is-invalid': submitted && f['shortName'].errors }"
            />
            <div *ngIf="submitted && f['shortName'].errors" class="invalid-feedback">
              <div *ngIf="f['shortName'].errors['required']">Short Name is required</div>
            </div>
          </div>
          <!-- <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label for="supplierName">Supplier Name<span class="requried-field">*</span></label>
            <input
              type="text"
             
              id="supplierName"
              class="form-control"
              formControlName="supplierName"
              [ngClass]="{ 'is-invalid': submitted && f['supplierName'].errors }"
            />
            <div *ngIf="submitted && f['supplierName'].errors" class="invalid-feedback">
              <div *ngIf="f['supplierName'].errors['required']">Supplier Name is required</div>
            </div>
          </div> -->
          <div class="form-group col-sm-12 col-md-12 col-lg-4">
            <label for="unitName">Unit Name<span class="requried-field">*</span></label>
            <select
            id="unitName"
            name="unitName"
            class="form-control"
            formControlName="unitName"
            [ngClass]="{ 'is-invalid': submitted && f['unitName'].errors }"
          >
          <option value="" selected disabled>--Please select--</option>
            <option
              *ngFor="let data of unitName"
              [value]="data"
              >{{ data }}
            </option>
          </select>
            <div *ngIf="submitted && f['unitName'].errors" class="invalid-feedback">
              <div *ngIf="f['unitName'].errors['required']">Unit Name is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-4">
            <label for="maintainStock">Maintain Stock<span class="requried-field">*</span></label>
            <select
                    id="maintainStock"
                    name="maintainStock"
                    class="form-control"
                    formControlName="maintainStock"
                    [ngClass]="{ 'is-invalid': submitted && f['maintainStock'].errors }"
                  >
                  <option value="" selected disabled>--Please select--</option>
                    <option
                      *ngFor="let data of maintainStock"
                      [value]="data"
                      >{{ data }}
                    </option>
                  </select>
            <div *ngIf="submitted && f['maintainStock'].errors" class="invalid-feedback">
              <div *ngIf="f['maintainStock'].errors['required']">Maintain Stock is required</div>
            </div>
          </div>
          <!-- <div class="form-group col-sm-12 col-md-12 col-lg-4">
            <label for="pstatus">Status<span class="requried-field">*</span></label>
            <mat-slide-toggle class="slide-enable" [color]="isEnabled ? ' rgba(0, 128, 0, 0.5) ' : 'default'" (change)="enabled()">Enable</mat-slide-toggle>
           
          </div> -->
      </div>
      <div class="formbutton">

      <button type="submit" class="btn btn-primary" (click)="onSubmit(productForm.value)">Submit</button>
      <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
    </div>
      </form>
      </div>